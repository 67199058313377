import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import ButtonRm from "../../Components/ButtonRm";
import Counter from "../../Components/Counter/";

export default function HomeTeamWrapper({ ourTeam, title }) {
  const [sectionTitle, setSectionTitle] = useState(title);
  const [summaryText, setSummaryText] = useState("Temel amacımız, müvekkillerimizin hukuksal işlemleri ve uyuşmazlıklarında hukuksal bilgiyi etkin ve doğru kullanarak hukuksal işlemlerin ve uyuşmazlıkların en hızlı ve en kolay şekilde çözülmesine yönelik hizmet vermektir. Ayrıca ofisimiz hizmet verdiği konularda uzman akademisyenlerle işbirliği yaparak müvekkillerinin ihtiyaç duyduğu çözümleri, geniş bir hukuki bilgi birikimine dayalı olarak akademik anlamda da geliştirmektedir.");

  return (
    <div className="row homepage-ourteam">
      <div className="col-12 col-xl-4 p-0">
        <div className="homepage-ourteam--box-wrapper">
          <h2>{sectionTitle}</h2>
          <p>
            {summaryText}
          </p>
          <ButtonRm type={"light"} text={"Devamı"} target={`${"/faaliyet-alanlari/arabuluculuk"}`}></ButtonRm>
        </div>
      </div>
      <div className="d-none d-xl-block col-xl-8">
        <div className="row">
          {ourTeam?.map((item, index) => {
            return (
              <div className="col-6 p-0">
                <div className="homepage-ourteam--box-wrapper">
                  <Counter
                    target={item.summary}
                    title={item.title}
                    desc={item.desc}
                    symbol={item.symbol}
                    duration={2}
                  />
                </div>
              </div>
            );
          })
          }
        </div>
      </div>
    </div>
  );
}
