export const getTodayDateWithFormat = () => {
  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  const days = [
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
    "Pazar",
  ];
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = months[today.getMonth()];
  const yyyy = today.getFullYear();
  const dayName = days[today.getDay() - 1];
  const formattedToday = dd + " " + mm + " " + yyyy + " " + dayName;
  return formattedToday;
};

export const getFormattedDate = (date, locale) => {
  return date.toLocaleDateString(locale ? locale : "tr-TR", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const getTranslated = (fromLang, toLang, text) => {
  const API_KEY = "AIzaSyCREojHL9ziah7CoIuO2BIHEye4oxyn2XE";
  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;
  url += "&q=" + encodeURI(text);

  if (
    fromLang !== toLang &&
    typeof fromLang !== "undefined" &&
    typeof toLang !== "undefined" &&
    typeof text !== "undefined"
  ) {

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("There was an error with the translation request: ", error);
      });
  } else {
    return Promise.resolve({
      data: {
        translations: [{ translatedText: text }],
      },
    });
  }
};



export const getRGLink = () => {
  const tP = {
    today: new Intl.DateTimeFormat('en-US',{day:'2-digit'}).format(new Date()),
    month: new Intl.DateTimeFormat('en-US',{month:'2-digit'}).format(new Date()),
    year: new Intl.DateTimeFormat('en-US',{year:'numeric'}).format(new Date()),
  };

  //https://www.resmigazete.gov.tr/eskiler/2024/09/20240912.pdf
  const link = `https://www.resmigazete.gov.tr/eskiler/${tP.year}/${tP.month}/${tP.year}${tP.month}${tP.today}.pdf`;
  return link;
};


export const Utils = {
  getTodayDateWithFormat: getTodayDateWithFormat,
  getRGLink: getRGLink,
  getFormattedDate: getFormattedDate,
  getTranslated: getTranslated,
};

export default Utils;
