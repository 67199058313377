import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import ButtonRm from "../../Components/ButtonRm";
import usePage from '../../Libs/_usePage';
import './index.scss'
export default function NotFoundPage({ page }) {
    const [text, setText] = useState("Üzgünüz, Aradığınız Sayfa Bulunamadı.");
    const { activePage } = usePage();

    return (
        <div className="not-found">
            <div className="not-found__wrapper">
                <h1>{activePage.id}</h1>
                <h5>{text}</h5>
                <ButtonRm text={"Anasayfaya Dön"} type={"light"} target={"/"} />
            </div>
        </div>
    )
}
