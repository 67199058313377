import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getTranslatedText } from "../../Libs/Utils"
import ButtonRm from '../ButtonRm/'
import userIcon from "../../Assets/Images/user-icon.svg";
import "./article.scss";

export default function Article({ image, heading, date, author, summary, target, hr }) {
  let { languagePrefix } = useParams();
  const [headingText, setHeadingText] = useState(heading);
  const [summaryText, setSummaryText] = useState(summary);
  /*useEffect(() => {
    Promise.all([
      getTranslatedText("tr", languagePrefix , headingText, setHeadingText),
      getTranslatedText("tr", languagePrefix , summaryText, setSummaryText),
    ])
}, [heading]);*/

  return (

    <div className="article">
      <div className="article--image">
        {image && <img src={image} alt="" />}
      </div>
      <div className="article--content">
        <p className="heading">{headingText}</p>

        {/*author && (
          <p className="author">
            <img src={userIcon} alt="Author icon." />
            {author.replace("-", " ")}
          </p>
        )*/}
        {date && <p className="date">{date}</p>}

        <p className="summary">
          {summaryText}
        </p>
      </div>
      <ButtonRm text={"Devamı"} target={target}></ButtonRm>
      {hr && <hr />}

    </div>

  );
}
