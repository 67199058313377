import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ActivityBox from "../../Components/ActivityBox/";

export default function ActivityBoxWrapper({ activities, title }) {
  const [sectionTitle, setSectionTitle] = useState(title);
  const [viewAllTitle, setViewAllTitle] = useState("Tümünü Görüntüle");


  return (
    <div className="row homepage-activities ">
      <div className="col-12 p-0">
        <h3>{sectionTitle}</h3>
      </div>
      {activities.map((item, index) => {
        if (index < 6) {
          return (
            <div className="col-12 col-md-6 col-xl-4 p-0">
              <div className="homepage-activities--activitybox-wrapper">
                <ActivityBox item={item} key={item.id}></ActivityBox>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}

      <div className="col-12 position-md-absolute p-5 homepage-activities--see-more">

        <Link to={`/faaliyet-alanlari`}>

          {viewAllTitle}
        </Link>
      </div>
    </div >
  );
}
