import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import pagesData from "../Data/Pages.json";

const usePage = () => {
    const location = useLocation();
    const { categorySlug, pageSlug } = useParams();
    const [page, setPage] = useState(false);
    const [parentPage, setParentPage] = useState(false);
    const [authorPage, setAuthorPage] = useState(false);
    const pages = pagesData.length ? pagesData[0]?.pages ? pagesData[0].pages : {} : {};
    const flatten = pages.map((f) => f?.children?.length ? [f, ...f.children] : f).flat();
    
    const getActivePage = (pathname) => {
        
        const errorPageData =
        {
            id: 404,
            name: "Not Found Error",
            slug: "/error",
        };

        const findPageData = flatten.find((p) => p.slug === pathname);
        const findParentPage = findPageData?.category?.length ? pages.find((p) => p.slugName === findPageData?.category) : false;
        setPage(findPageData?.id ? findPageData : errorPageData);
        setParentPage(findParentPage);
    };

    const getAuthorPage = (author) => {
        const findAuthorPage = author?.length ? flatten.find((p) => p.slugName === author) : false;  
        return findAuthorPage;
    }

    


    useEffect(() => {
        getActivePage(location.pathname);
    }, [location]);

    useEffect(() => {
    }, [page])

    return {
        activePage: page,
        allPages: pages,
        parentPage:parentPage,
        getAuthorPage:getAuthorPage
    }

};

export default usePage;