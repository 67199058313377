import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import FooterNavigationItem from "./FooterNavigationItem";

export default function FooterNavigation({ pages }) {
  return (
    <>
      <ul>
        {pages.filter((page) => page.isActive).map((page) => {
          return (
            <FooterNavigationItem page={page} />
          );
        })}
      </ul>
      <p className="copyright-text"> © 2021 Arslanbaş Hukuk, tüm hakları saklıdır.</p>
    </>
  );
}
