import React, { useState, useEffect } from 'react'
import { useParams, Link } from "react-router-dom";
import "./button_rm.scss";

export default function ButtonRm({ text, target, type }) {
  const [buttonText, setButtonText] = useState(text);
  const baseClasses = ["button-rm"];
  const extraClasses = type ? ((type === 'light' ? baseClasses.push("button-rm--light") : (type === 'inverse' ? baseClasses.push("button-rm--inverse") : null))) : null;
  const targetUrl = target ? target : null;


  return (
    <Link to={targetUrl}>
      <button className={baseClasses.join(" ")}>{buttonText}</button>
    </Link>
  );
}
