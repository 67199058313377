import React from "react";
import ReactDOM from "react-dom/client";
import Routes from "./Routes/";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./Libs/reportWebVitals";
import "./Assets/Styles/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
      <Router>
          <Routes />
      </Router>
  </React.StrictMode>
);



reportWebVitals();
