import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import "./footer.scss";
import locationIcon from "../../Assets/Images/location.svg";
import FooterNavigation from "../../Components/FooterNavigation";
import mailIcon from "../../Assets/Images/mail.svg";
import phoneIcon from "../../Assets/Images/phone.svg";
import pagesData from "../../Data/Pages.json";

export default function Footer() {
  const location = useLocation();
  const allPages = pagesData[0].pages;
  const [title, setTitle] = useState("İletişim Bilgilerimiz");
  const [routeText, setRouteText] = useState("YOL TARİFİ");

  return (
    <div className="container-fluid footer">
      <div className="row">
        <div className="col-12 col-lg-7 col-xl-8 p-0">
          <div className="footer-links bg-gradient--brown">
            <FooterNavigation pages={allPages} />
          </div>
        </div>
        <div className="col-12 col-lg-5 col-xl-4 p-0">
          <div className="footer-info">
            <h6>{title}</h6>
            <div>
              <img src={locationIcon}></img>
              <p>Cevizli Mh. Bulut Plaza Tansel Cd. No:18/80 Maltepe / İstanbul</p>
            </div>
            <div>
              <img src={phoneIcon}></img>
              <p>+90 216 399 71 04</p>
            </div>
            <div>
              <img src={mailIcon}></img>
              <p>info@arslanbas.av.tr</p>
            </div>
            <div className="route-btn">
              <a href="https://goo.gl/maps/HXYHR8fL6k4Ka5Dk9" target="_blank" className="btn btn-primary float-right py-3 px-5">{routeText}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
