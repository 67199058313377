import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import bg from "../../Assets/Images/aboutus-bg.jpg";
import SubNavigation from "../SubNavigation";
import AuthorBox from "../AuthorBox";
import './index.scss'

export default function EduItem({ item }) {
  let { languagePrefix } = useParams();
  const [text, setText] = useState(item.schoolname);

  return (
    <p key={item.id}>
      <span>{item.date}</span> {text}
    </p>
  );
}
