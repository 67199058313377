import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { getTranslatedText } from "../../Libs/Utils"
import './index.scss'
import userIcon from "../../Assets/Images/businessman-svgrepo-com.svg";
import usePage from '../../Libs/_usePage';

export default function AuthorBox({ authorName }) {
  const [author, setAuthor] = useState(false);
  const {getAuthorPage} = usePage();
  
  
  const getLink = (info) => {
    return info.type + info.name
  }

  useEffect(() => {
    setAuthor(getAuthorPage(authorName));
  }, [])

  return author?.job_title ? (
    <div className="author-box">
      <div className="author-box--text">
        <p>{author.job_title} {author.title}</p>
        <p>İletişim Bilgileri</p>
        {
          author.contact_information.map(info => { return <a href={getLink(info)}>{info.show ? info.name : null}</a> })
        }
      </div>
    </div>
  ) : null
}
