import React, { useState,useRef, useEffect } from 'react'
import "./index.scss";
import ButtonRm from "../ButtonRm/"
import Slider from "react-slick";
import CarouselItem from "./CarouselItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Carousel({ data }) {
  const title = useState()
  let sliderRef = useRef(null);
  
  const onClickPrev = () => {
    sliderRef.slickPrev();
  }
  const onClickNext = () => {
    sliderRef.slickNext();
  }

  const getCarouselItem = (item, index) => {
    return <CarouselItem item={item} index={index} />
  }

  return (
    <div className="carousel">
      <Slider settings={{
    dots: false,
    infinite: true,
    speed: 500,
    arrows:false,
    slidesToShow: 1,
    autoplay:true,
    slidesToScroll: 1,
      }} className="owl-theme" ref={slider => {
        sliderRef = slider;
      }}>
        {data?.items.map((item, index) => getCarouselItem(item, index))}
      </Slider>
      <div className="owl-navigation">
        <div className="owl-navigation--controls">
          <div onClick={() => onClickPrev()} className="owl-prev"></div>
          <div onClick={() => onClickNext()} className="owl-next"></div>
        </div>
      </div>
    </div>
  );

  return <p>slider</p>
}
