import React from 'react';
import MemberCard from "../../Components/MemberCard";
import usePage from '../../Libs/_usePage';
import SubNavigation from "../../Components/SubNavigation";
import "./index.scss";

export default function OurTeamPage() {
  const {activePage, allPages} = usePage();
  return (
    <div className="container-fluid ourteampage ">
      <div className="row position-relative">
        <div className="col-12 p-0">
          <div className="subnavigation-wrapper">
            <SubNavigation page={activePage} pages={allPages}></SubNavigation>
          </div>
        </div>
      </div>
      <div className="row">
        {activePage?.children?.map((member) => {
          return (
            <div className="col-12 col-md-6 col-xl-4 p-0">
                <div className="member-card-wrapper">
                    <MemberCard data={member}></MemberCard>
                </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
