import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { getFormattedDate, getTodayDateWithFormat } from "../../Libs/Utils";
import Article from "../../Components/Article";
import SubNavigation from "../../Components/SubNavigation";

import "./index.scss";
import usePage from "../../Libs/_usePage";

export default function List() {
  const {activePage} = usePage();
  
  const getImageUrl = (article) => {
    return null;
  };



  const getDateOfArticle = (date) => {
    return date ? getFormattedDate(
          new Date(date[0], date[1], date[2]),
          "tr"
        )
      : null;
  };


  return (
    <div
      className={`${`${activePage?.template}-page sample-page container-fluid border-bottom`}`}
    >
      <div className="row position-relative">
        <div className="col-12 p-0">
          <div className="subnavigation-wrapper">
            <SubNavigation></SubNavigation>
          </div>
        </div>
      </div>
      <div className="row">
        {activePage?.children?.map((article) => {
          return (
            <div className="col-12 col-md-6 col-xl-4 p-0 column-borders">
              <div className="article-wrapper">
                <Article
                  key={article.id}
                  image={getImageUrl(article)}
                  heading={article.title}
                  summary={article.summary}
                  date={getDateOfArticle(article.date)}
                  author={article?.author?.length ? article.author : false}
                  target={`${article.slug}`}
                  hr={true}
                ></Article>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
