import React, { useState, useEffect, act } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import SubNavigationMenuItem from "./SubNavigationMenuItem";
import dropdownIcon from "../../Assets/Images/dropdown-icon.svg";
import usePage from "../../Libs/_usePage";
import "./index.scss";

export default function SubNavigation() {
  const {activePage, allPages, parentPage} = usePage();
  const location = useLocation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(activePage?.children?.length);
  const [menuItems, setMenuItems] = useState([]);
  
  
  const onCategoryClick = () => {
    showMenu ? navigate(activePage.slug) : setShowMenu(true);
  };

  useEffect(() => {
    if(window.location.pathname === location.pathname){
      setShowMenu(false); 
    } else {
      setShowMenu(false);
      setMenuItems([]);
    }

  }, [location]);

  useEffect(() => {
    setMenuItems(activePage.children)
    if(activePage?.showSiblingsMenuInChildPages && parentPage?.children){
      setMenuItems(parentPage?.children) 
    }
    
  }, [activePage, parentPage]);



  return (
    <div className="subnavigation">
      <div className="subnavigation-links">
        {menuItems?.length > 0 ? (
          <ul className={showMenu ? "show" : ""}>
            <button
              onClick={() => {
                onCategoryClick();
              }}
            >
              {activePage?.showSiblingsMenuInChildPages ? parentPage.title : activePage.title}
            </button>
            <div className="icon-wrapper" onClick={() => {
                onCategoryClick();
              }}>
              <img src={dropdownIcon} />
            </div>
            {menuItems?.map((item) => (
              <SubNavigationMenuItem item={item} onClick={() => setShowMenu(false)} />
            ))}
          </ul>
        ) : null}
      </div>
      <h1>{activePage.title}</h1>
    </div>
  );
}
