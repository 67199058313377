import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "./index.scss";

export default function ActivityBox({ item }) {
  const [title, setTitle] = useState(item.title);
  const [summary, setSummary] = useState(item.summary);

  return (
    <Link to={item.slug}>
      <div className="activity-box">
        <img src={item.images.find((image) => image.type === "icon").url} alt="our activities icon"></img>
        <h4>{title}</h4>
        <p>{summary}</p>
      </div>
    </Link>
  );
}
