import {React,useState} from 'react'
import Navigation from "../Navigation/";
import pagesData from "../../Data/Pages.json";

export default function SideBar() {
    const allPages = pagesData[0].pages;
    const [showNavigation, setShowNavigation] = useState(false);
    const [showNavigationClass, setShowNavigationClass] = useState("");
    const onSetShowNavigation = (state) => {
        setShowNavigation(state);
        setShowNavigationClass(!showNavigation ? "active" : "not-active");

    }

    return (
        <>
            <div className="arslanbas-sidebar sticky-top">
                <div className="arslanbas-sidebar--icon" onClick={()=>onSetShowNavigation(!showNavigation)}>
                    <div className="menu-icon-wrapper">
                        <div className={showNavigationClass}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <h4 className="d-md-none text-light">arslanbaş</h4>
                </div>
                <div className="arslanbas-sidebar--brandtext">
                    <a href="/">
                      <h1 className="brand-text">arslanbaş hukuk <br/> arabuluculuk</h1>
                    </a>
                </div>
                <div className="arslanbas-sidebar--language-wrapper d-none d-lg-block">
                </div>
            </div>
            {
               <Navigation showNavigation={showNavigation} setShowNavigation={onSetShowNavigation} pages={allPages.filter((p) => !p.hideHeader)}></Navigation>
            }
        </>
    )
}
