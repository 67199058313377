import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import bg from "../../Assets/Images/aboutus-bg.jpg";
import bgImage from "../../Assets/Images/aboutus-bg.jpg";

import SubNavigation from "../../Components/SubNavigation";
import AuthorBox from "../../Components/AuthorBox";
import usePage from "../../Libs/_usePage";
import "./index.scss";
export default function ArticleDetailPage() {
  const {activePage, allPages} = usePage();
  
  

  return (
    <div
      className="container-fluid article-detail-page"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="row position-relative">
        <div className="col-12 p-0">
          <div className="subnavigation-wrapper">
            <SubNavigation />
          </div>
        </div>
        <div className="col-12 col-xl-8 bg-white p-0">
          <div className="article-detail-page--content">
            <div className="article-detail-page--content_text" dangerouslySetInnerHTML={{__html:activePage.text}}>
            </div>
            {activePage?.author &&
              <div className="article-detail-page--content_author">
                <AuthorBox
                  authorName={activePage?.author}
                ></AuthorBox>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
