import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { getTranslatedText } from "../../Libs/Utils";
import leftIcon from "../../Assets/Images/anouncement-right.png";
import resmiGazete from "../../Assets/Images/resmi-gazete.png";
import uyapLogo from "../../Assets/Images/uyap-logo.png";
import yargitayLogo from "../../Assets/Images/yargitay-logo.png";
import istanbulBaroLogo from "../../Assets/Images/istanbul-barosu.png";
import { getTodayDateWithFormat, getRGLink } from "../../Libs/Utils";

export default function HomeTeamWrapper({ ourTeam, title }) {
  let { languagePrefix } = useParams();
  const [uyapTitle, setUyapTitle] = useState("Uyap Bilişim Sistemi");
  const [uyapDesc, setUyapDesc] = useState(
    "Adalet Bakanlığı ortak erişim portalına buradan erişebilirsiniz."
  );
  const [baroTitle, setBaroTitle] = useState("İstanbul Barosu");
  const [baroDesc, setBaroDesc] = useState(
    "İstanbul Barosu Yargıtay Karar Arşivine buradan erişebilirsiniz."
  );
  const [yargitayTitle, setYargitayTitle] = useState("Yargıtay");
  const [yargitayDesc, setYargitayDesc] = useState(
    "Yargıtay Emsal Karar Arşivine buradan erişebilirsiniz."
  );
  const [rgTitle, setRgTitle] = useState("T.C. Resmi Gazete");
  const [rgDate, setRgDate] = useState(getTodayDateWithFormat());
  const [rgDesc, setRgDesc] = useState(
    " tarihli T.C. Resmî Gazete'yi okuyabilirsiniz. "
  );


  return (
    <div className="row d-md-flex homepage-announcements align-items-center">
      <div className="col-12 col-sm-12 col-lg-8 order-1 homepage-announcements--left">
        <div className="row align-items-center h-100 ann-item">
          <div className="col-3">
            <img
              src={uyapLogo}
              alt="Announcments left icon."
              className="img-fluid"
            ></img>
          </div>
          <div className="col-9">
            <div className="row justify-content-center ">
              <div className="col-12">
                <h4>{uyapTitle}</h4>
                <h6>{uyapDesc}</h6>
              </div>
            </div>
          </div>
          <a
            target="_blank"
            href="https://arabulucu.uyap.gov.tr/main/arabulucu/index.jsp"
            className="read-more"
          >
            #
          </a>
        </div>
        <div className="row align-items-center h-100 ann-item">
          <div className="col-3">
            <img
              src={istanbulBaroLogo}
              alt="Announcments left icon."
              className="img-fluid"
            ></img>
          </div>
          <div className="col-9">
            <div className="row justify-content-center">
              <div className="col-12">
                <h4>{baroTitle}</h4>
                <h6>{baroDesc}</h6>
              </div>
            </div>
          </div>
          <a
            target="_blank"
            href="https://www.istanbulbarosu.org.tr/karararama/arsiv.aspx"
            className="read-more"
          >
            #
          </a>
        </div>
        <div className="row align-items-center h-100 ann-item">
          <div className="col-3">
            <img
              src={yargitayLogo}
              alt="Announcments left icon."
              className="img-fluid"
            ></img>
          </div>
          <div className="col-9">
            <div className="row justify-content-center ">
              <div className="col-12">
                <h4>{yargitayTitle}</h4>
                <h6>{yargitayDesc}</h6>
              </div>
            </div>
          </div>
          <a
            target="_blank"
            href="https://karararama.yargitay.gov.tr/"
            className="read-more"
          >
            #
          </a>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-lg-4 order-2 homepage-announcements--right h-100">
        <div className="row align-items-center h-100 ann-item">
          <div className="col-4">
            <img
              src={resmiGazete}
              alt="Announcments left icon."
              className="img-fluid"
            ></img>
          </div>
          <div className="col-8">
            <h4>{rgTitle}</h4>
            <h6>
              <strong>{rgDate}</strong> {rgDesc}
            </h6>
          </div>
          <a target="_blank" href={getRGLink()} className="read-more">
            #
          </a>
        </div>
      </div>
    </div>
  );
}
