import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";

export default function SubNavigationItem({ item, pages, languagePrefix, onClickNavigationItem }) {
    const [title, setTitle] = useState(item.title);

    return (
        <Link key={item.id} onClick={() => onClickNavigationItem} to={`${item.slug}`}>{title.length > 64 ? `${title.substring(0, 64)}...` : title}</Link>
    )
}
