import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

export default function FooterNavigationItem({ page }) {
  const [title] = useState(page.title);

  return (
    <li key={page.id}>
      <Link to={`${page.slug}`}>{title}</Link>
    </li>
  );
}
