import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import ScrollToTop from "../Components/ScrollToTop";
import SideBar from "../Components/SideBar/";
import LoadingBar from "react-top-loading-bar";
import usePage from "../Libs/_usePage";
import { useNavigate } from "react-router";
import loadingIconW from "../Assets/Images/pngaaa.com-298469-w.png";
import loadingIcon from "../Assets/Images/pngaaa.com-298469.png";
import "./default.scss";
import { Helmet } from "react-helmet";


export default function DefaultLayout(props) {
  const [progress, setProgress] = useState(0);
  const { allPages, activePage } = usePage();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (activePage?.id) {
      if(activePage.id === 404){
        //navigate("/hata");
      }
      setProgress(10);
      setTimeout(setProgress(30), 400);
      setTimeout(setProgress(60), 800);
      setTimeout(setProgress(100), 1200);
      setLoading(false);
      
    }
  }, [activePage]);

  const getLoadingScreen = () => {
    return (
      <div className="loadingWrapper">
        <div className="loadingContentWrapper">
          <div className="logoWrapper">
            <img width="64" src={loadingIconW} />
            <div className="duplicatedLogoWrapper">
              <img width="64" c src={loadingIcon} />
            </div>
            <p className="loadingBrandName"> arslanbaş </p>
          </div>
        </div>
      </div>
    );
  };

  const getSeoTags = () => {
    return (
      <Helmet>
        <title>
          {activePage?.seo?.title
            ? activePage?.seo?.title
            : "Arslanbaş Hukuk & Arabuluculuk, Danışmanlık"}
        </title>

        <meta
          name="description"
          content={
            activePage?.seo?.description
              ? activePage?.seo?.description
              : "İnsan odaklı işler, kalıcı ilişkiler."
          }
        />
        <meta
          property="og:site_name"
          content={
            activePage?.seo?.title
              ? activePage?.seo?.title
              : "Arslanbaş Hukuk & Arabuluculuk, Danışmanlık"
          }
        />
      </Helmet>
    );
  };

  const getLayout = () => {
    return (
      <React.Fragment>
        {activePage?.seo ? getSeoTags() : null}
        <div className="arslanbas">
          <ScrollToTop />
          <SideBar />
          <div className="arslanbas-content">
            <LoadingBar
              color="#ba9a7a"
              waitingTime={3000}
              height={3}
              progress={progress}
              onLoaderFinished={() => {
                setProgress(0);
                setIsReady(true);
              }}
            />
            {props.children}
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  };

  return loading ? getLoadingScreen() : getLayout();
}
