import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getFormattedDate } from "../../Libs/Utils"
import Article from "../../Components/Article/";

export default function Carousel({ articles, title }) {
  const [sectionTitle, setSectionTitle] = useState(title);

  return (
    <div className="article-wrapper">
      <h4>{sectionTitle}</h4>

      {articles?.map((article, i) => {
        return i < 2 ? (
          <Article
            heading={article.title}
            date={getFormattedDate(new Date(article.date[0], article.date[1], article.date[2]))}
            summary={article.summary}
            hr={i < 1 ? true : false}
            article={article}
            key={i}
            target={`${article.slug}`}
          ></Article>
        ) : null;
      })}
    </div>
  );
}
