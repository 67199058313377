import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getTranslatedText } from "../../Libs/Utils"
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import './counter.scss'

const Counter = ({ target, title, desc, symbol, duration }) => {
    const [headingText, setHeadingText] = useState(title);
    const [summaryText, setSummaryText] = useState(desc);



    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true,
    });

    return (
        <div className="counter" ref={ref}>
            <CountUp
                start={0}
                end={inView ? target : 0}
                duration={duration}
                separator='.'
                useEasing={true}>
                {({ countUpRef }) => (
                    <h1 data-symbol={symbol} className="counter-number" ref={countUpRef} />
                )}
            </CountUp>
            <h3 className="counter-title">
                {headingText}
            </h3>
            <p>
                {summaryText}
            </p>
            <style jsx>{`
                .delay {
                    transition-delay: ${duration}s;
                }
                .transform-hide {
                    transform: translate3d(0, 1rem, 0);
                }
                .transform-show {
                    transform: translate3d(0, 0, 0);
                }
            `}</style>
        </div>
    );
};

export default Counter;