import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DefaultLayout from "../Layout/default";
import ErrorLayout from "../Layout/error";
import NotFound from "../Pages/NotFound";
import HomePage from "../Pages/Homepage";
import OurTeam from "../Pages/OurTeam";
import MemberDetails from "../Pages/MemberDetails";
import Contact from "../Pages/Contact";
import List from "../Pages/List";
import ArticleDetails from "../Pages/ArticleDetails";
import "./index.scss";

export default function RouteConfig(props) {
  const getRoutes = () => {
    return (
      <div className="arslanbas">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <DefaultLayout {...props}>
                <HomePage />
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/kurumsal"
            element={
              <DefaultLayout {...props}>
                <ArticleDetails />
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/ekibimiz"
            element={
              <DefaultLayout {...props}>
                <OurTeam />
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/ekibimiz/:member"
            element={
              <DefaultLayout {...props}>
                <MemberDetails />
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/:categorySlug"
            element={
              <DefaultLayout {...props}>
                <List />
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/:categorySlug/:pageSlug"
            element={
              <DefaultLayout {...props}>
                <ArticleDetails />
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/iletisim"
            element={
              <DefaultLayout {...props}>
                <Contact />
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/gizlilik-politikasi"
            element={
              <DefaultLayout {...props}>
                <ArticleDetails />
              </DefaultLayout>
            }
          />

          <Route
            path="/hata"
            element={
              <ErrorLayout {...props}>
                <NotFound {...props} />{" "}
              </ErrorLayout>
            }
          />

          <Route
            path="*"
            element={
              <ErrorLayout {...props}>
                <NotFound {...props} />{" "}
              </ErrorLayout>
            }
          />
        </Routes>
      </div>
    );
  };

  return getRoutes();
}
