import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import bg from "../../Assets/Images/aboutus-bg.jpg";
import SubNavigation from "../../Components/SubNavigation/";
import AuthorBox from "../../Components/AuthorBox/";
import EduItem from "../../Components/MemberCard/eduItem";
import './index.scss'
import usePage from '../../Libs/_usePage';

export default function MemberDetailPage() {
  const [text, setText] = useState( "Eğitim Bilgileri");
  const {activePage, allPages} = usePage();

  useEffect(() => {
    setText(activePage?.education?.length !== 0 ? "Eğitim Bilgileri" : "Eğitim Bilgileri Girilmemiş.")
  }, [activePage])
  return (
    <div
      className="container-fluid member-detail-page"
      style={{
        backgroundImage: `url("/Assets/Images/aboutus-bg.jpg")`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="row position-relative">
        <div className="col-12 p-0">
          <div className="subnavigation-wrapper">
            <SubNavigation showSiblingsMenuInChildPages={true}></SubNavigation>
          </div>
        </div>
        <div className="col-12 col-xl-8 bg-white">
          <div className="member-detail-page--content">
            <div className="member-detail-page--content_text">
              <h1>{text}</h1>
              {activePage?.education?.map((edu) => <EduItem item={edu} />)}
            </div>
            {activePage ? 
              <div className="member-detail-page--content_author">
                <AuthorBox
                  authorName={activePage?.author}
                ></AuthorBox>
              </div> : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}
