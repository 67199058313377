import React, { useState, useEffect } from 'react';
import SubNavigationItem from './SubNavigationItem';
import { useLocation, Link } from "react-router-dom";
import { getTranslatedText, getTranslated } from "../../Libs/Utils"

export default function NavigationItem({ item, pages, page, languagePrefix, onClickNavigationItem, withChildren }) {
    const [title, setTitle] = useState(page.title);
    useEffect(() => {

        /*Promise.all([
            getTranslatedText("tr", languagePrefix, title, setTitle),
        ])*/
    }, [page]);
    return (
        <Link to={page.slug} key={page.id} onClick={() => { onClickNavigationItem() }}>
            <li className="arslanbas-navigation--links">
                {title.length > 21 ? `${title.substring(0, 21)}...` : title}
                {withChildren ? <div className="arslanbas-navigation--links__sublinks d-none"> {page.children.map(item => <SubNavigationItem languagePrefix={languagePrefix} onClickNavigationItem={onClickNavigationItem} pages={pages} item={item} />)} </div> : null}
            </li>
        </Link>
    )
}

