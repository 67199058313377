import React from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import Carousel from "../../Components/Carousel/";
import ArticleWrapper from "../../Components/ArticleWrapper/";
import ActivityBoxWrapper from "../../Components/ActivityBoxWrapper/";
import HomeTeamWrapper from "../../Components/HomeTeamWrapper/";
import HomeAboutBox from "../../Components/HomeAboutBox/";
import HomeAnnouncements from "../../Components/HomeAnnouncements/";
import usePage from "../../Libs/_usePage";
import pagesData from "../../Data/Pages.json";
import "./index.scss";





export default function HomePage() {
  const allPages = pagesData[0].pages;
  const activePage = allPages.find((p) => p.slug === "/");
  const court_decisions = allPages.find(
    (page) => page.slugName === "yargitay-kararlari"
  ).children;
  const activities = allPages.find((page) => page.slugName === "faaliyet-alanlari")
    .children;
  const articles = allPages.find((page) => page.slugName === "makaleler")
    .children;
  return (
    <div className="container-fluid homepage">
      <div className="row">
        <div className="col-12 p-0">
          <div className="homepage-carousel-wrapper">
            <Carousel data={activePage.carousel}></Carousel>
          </div>
        </div>
      </div>
      <div className="row homepage-articles">
        <div className="col-12 col-lg-6 col-xl-4 bordered-column p-0">
          <ArticleWrapper title={"Yargıtay Kararları"} articles={court_decisions} />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 bordered-column p-0 border-top">
          <ArticleWrapper title={"Makaleler"} articles={articles} />
        </div>
        <div className="col-12 col-xl-4 bordered-column p-0">
          <HomeAboutBox />
        </div>
      </div>
      <ActivityBoxWrapper activities={activities} title="Faaliyet alanları" />
      <HomeTeamWrapper title="Ülkemizde Arabuluculuk" ourTeam={activePage.ourteam} />
      <HomeAnnouncements />
    </div>
  );
}
