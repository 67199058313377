import React, { useState, useEffect } from 'react'
import { useParams, useLocation, Link } from "react-router-dom";
import ButtonRm from "../ButtonRm/"
import "./index.scss";

export default function Carousel({ item, index }) {
  const [title, setTitle] = useState(item.title)
  const [subtitle, setSubtitle] = useState(item.subtitle)

  return (
    <div
      key={index}
      className="item"
      style={{
        backgroundImage: `url(${item.image_family[0].url})`
      }}
    >
      <div className="item-content">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        <ButtonRm type={"inverse"} text={"Devamı"} target={item.target}> </ButtonRm>
      </div>
    </div>
  );
}
