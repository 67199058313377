import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import ScrollToTop from "../Components/ScrollToTop";
import SideBar from "../Components/SideBar/";
import LoadingBar from "react-top-loading-bar";
import usePage from "../Libs/_usePage";
import loadingIconW from "../Assets/Images/pngaaa.com-298469-w.png";
import loadingIcon from "../Assets/Images/pngaaa.com-298469.png";
import "./default.scss";

export default function ErrorLayout(props) {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const { allPages, activePage } = usePage();
  
  useEffect(() => {
    if (activePage?.id) {
      setProgress(10);
      setTimeout(setProgress(30), 400);
      setTimeout(setProgress(60), 800);
      setTimeout(setProgress(100), 1200);
      setLoading(false);
    }
  }, [activePage]);
  const getLayout = () => {
    return (
      <React.Fragment>
        <div className="arslanbas">
          <ScrollToTop />
          <SideBar />
          <div className="arslanbas-content">
            <LoadingBar
              color="#ba9a7a"
              waitingTime={3000}
              height={3}
              progress={progress}
              onLoaderFinished={() => {
                setProgress(0);
                setIsReady(true);
              }}
            />
            {props.children}
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  };
  return getLayout();
}
