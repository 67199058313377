import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ButtonRm from "../ButtonRm";
import { getTranslatedText } from "../../Libs/Utils"
import Article from "../../Components/Article/";

export default function HomeAboutBox() {
  let { languagePrefix } = useParams();
  const [sectionTitle, setSectionTitle] = useState("Arslanbaş Hukuk & Arabuluculuk");
  const [sectionContentFirst, setSectionContentFirst] = useState("2007 yılından bu yana siz müvekkillerine hukuksal danışmanlık ve avukatlık hizmeti vermektedir. Hukuk ofisimiz, alanında uzman olan dinamik kadrosu ve güçlü çözüm ortaklarıyla birlikte birçok hukuk dalında müvekkillerine en iyi hizmeti vermeyi kendisine nihai hedef edinmiştir.");
  const [sectionContentLast, setSectionContentLast] = useState("Temel amacımız, müvekkillerimizin hukuksal işlemleri ve uyuşmazlıklarında hukuksal bilgiyi etkin ve doğru kullanarak hukuksal işlemlerin ve uyuşmazlıkların en hızlı ve en kolay şekilde çözülmesine yönelik hizmet vermektir. Ayrıca ofisimiz hizmet verdiği konularda uzman akademisyenlerle işbirliği yaparak müvekkillerinin ihtiyaç duyduğu çözümleri, geniş bir hukuki bilgi birikimine dayalı olarak akademik anlamda da geliştirmektedir.");

  return (
    <div className="about-us-article">
      <h4>{sectionTitle}</h4>
      <p>
        {sectionContentFirst} <br></br> <br></br>
        {sectionContentLast}
        {" "}
        <br></br> <br></br>
        <br></br> <br></br>
      </p>
      <ButtonRm text={"Devamı"} target={"/kurumsal"}></ButtonRm>
    </div>
  );
}
