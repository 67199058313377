import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import VCard from 'vcard-creator'
import {QRCodeSVG} from "qrcode.react";
import userIcon from "../../Assets/Images/user-icon.svg";
import "./index.scss";




export default function MemberCard({ data }) {
  const [showDetails, setShowDetails] = useState(true);
  const [personalInformationTitle, setPersonalInformationTitle] = useState("Kişisel Bilgiler");
  const [viewCVText, setViewCVText] = useState("Öz Geçmiş Görüntüle");
  const [jobTitle, setJobTitle] = useState(data.job_title);

  const getVCard = (user) => {
    const vCard = new VCard()
    vCard.addName(user.lastName, user.name)
    .addCompany('Arslanbaş Hukuk Arabulucuk Danışmanlık')
    .addJobtitle('Avukat')
    .addPhoneNumber(user.contact_information[1].name, "WORK")
    .addEmail(user.contact_information[0].name)
    .addURL('https://www.arslanbas.av.tr')
    return vCard.toString();
  }

  const downloadTxtFile = (value, name) => {
    const element = document.createElement("a");
    const file = new Blob([value], { type: 'application/octet-stream' });
    element.href = URL.createObjectURL(file);
    element.download = data.name + data.lastName + '.vcf';
    document.body.appendChild(element);
    element.click();
  }

  const onShowDetails = (status) => {
    setShowDetails(status)
  }

  return (
    <>
      <div className="member-card">
        {showDetails ? (
          <div
            className="member-card--information-side"
            onClick={() => onShowDetails(true)}
          >
            <div className="member-card--information-side_details">
              <div className="qr-vcf-wrapper">
                <QRCodeSVG
                  onClick={() => downloadTxtFile(getVCard(data))}
                  value={getVCard(data)}
                  size={48}
                />
              </div>
              <h1>{personalInformationTitle}</h1>
              {data.contact_information
                .filter((item) => item.show)
                .map((info) => (
                  <div>
                    <h2>{info.title}</h2>
                    <p>{info.name}</p>
                  </div>
                ))}
            </div>
            <Link to={`${data.slug}`}>
              {viewCVText}
            </Link>
          </div>
        ) : (
          <div
            className="member-card--icon-side"
            onClick={() => onShowDetails(true)}
          >
            <span>{jobTitle}</span>
          </div>
        )}
      </div>
      <div className="member-name">
        <p>
          <span>{jobTitle}</span> / {data.title}
        </p>
      </div>
    </>
  );
}
