import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import Map from '../../Components/Map'
import checkMessageSVG from '../../Assets/Images/check-message.svg';
import './index.scss'


export default function ContactPage() {
    const [isSent, setIsSent] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    const [contactInformationTitle, setContactInformationTitle] = useState("İletişim Bilgilerimiz");
    const [contactFormTitle, setContactFormTitle] = useState("İletişim Formu");
    const [adresLabel, setAdresLabel] = useState("Adres");
    const [phoneLabel, setPhoneLabel] = useState("Telefon");
    const [submitButtonLabel, setSubmitButtonLabel] = useState("GÖNDER");
    const [NameSurnameLabel, setNameSurnameLabel] = useState("AD SOYAD");
    const [NameSurnameInputPlaceholder, setNameSurnameInputPlaceholder] = useState("Adınız, Soyadınız");
    const [NameSurnameWarningMessage, setNameSurnameWarningMessage] = useState("Lütfen Adınızı ve Soyadınızı Belirtiniz.");
    const [emailAdressLabel, setEmailAdressLabel] = useState("E-POSTA ADRESI");
    const [emailWarningMessage, setEmailWarningMessage] = useState("Lütfen E-posta Adresinizi Belirtiniz.");
    const [emailMissingMessage, setEmailMissingMessage] = useState("E-posta adresiniz geçersiz.");
    const [messageLabel, setMessageLabel] = useState("Mesajınız");
    const [successMessage, setSuccessMessage] = useState("Teşekkürler, mesajınız bize ulaştı.")
    const [messageMissingMessage, setMessageMissingMessage] = useState("Lütfen Mesajınızı Boş Bırakmayınız.")

    const onSubmit = (data) => {
        sendFormActions(data)
    };

    const sendFormActions = (data) => {
        data.toName = "Yönetici";
        emailjs.send('service_yjp54fm', 'template_6qmgw1m', data, 'user_Wwo7S1ed1QA3eVz39bWZe').then((result) => {
            console.log('data, result.text', data, result.text);
            setIsSent(true);
        }, (error) => {
            console.log(error.text);
        })
    }

    return (
        <div className="container-fluid contactpage">
            <div className="row position-lg-relative" >
                <div className="col-12 p-0" style={{ height: "1200px" }}>
                <Map
                        ismMarkerShown
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=tr&key=AIzaSyCovNmsBtNoQxA5ZV6CO8FXks05KBl4qdw"
                        loadingElement={<div className="h-100"></div>}
                        containerElement={<div style={{ height: "100%" }}></div>}
                        mapElement={<div className="h-100"></div>}
                    ></Map>
                </div>
                <div className="col-12 col-lg-8 position-lg-absolute contactpage-form" >
                    <div className="row">
                        <div className="col-12">
                            <h5>{contactInformationTitle}</h5>
                        </div>
                        <div className="col-4"><p>{adresLabel}</p></div><div className="col-8 border-bottom"><p>Cevizli, Bulut Plaza, Tansel Cd. No:18 / 80, 34846 Maltepe / İstanbul</p></div>
                        <div className="col-4"><p>{phoneLabel}</p></div><div className="col-8 border-bottom"><p>+90 216 399 71 04</p></div>
                        <div className="col-4"><p>Email</p></div><div className="col-8 border-bottom"><p>info@arslanbas.av.tr</p></div>
                        <div className="col-12">
                            <h5>{contactFormTitle}</h5>
                        </div>
                        <div className="col-12">
                            <div className={isSent ? "form-sent isSent" : "form-sent"}>
                                <div className="form-sent-wrapper">
                                    <div className="form-sent-icon">
                                        <img src={checkMessageSVG} />
                                    </div>
                                    <div className="form-sent-text">{successMessage}</div>
                                </div>
                            </div>
                            <form
                                id="ea-form"
                                onSubmit={handleSubmit(onSubmit)}
                                autoComplete="off"
                                noValidate
                            >
                                <div className="form-group">
                                    <label htmlFor="firstname" className="text-uppercase">{NameSurnameLabel}</label>
                                    <input type="text" className="form-control"
                                        placeholder={NameSurnameInputPlaceholder}
                                        id="firstname"
                                        name="firstName"
                                        {...register("firstName",{
                                            required: {
                                                value: true,
                                                message: NameSurnameWarningMessage,
                                            },
                                        })}
                                        required />

                                    {errors.firstName && errors.firstName.type === "required" && (
                                        <p className="error-label">{errors.firstName.message}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="text-uppercase" htmlFor="workemail">{emailAdressLabel}</label>
                                    <input type="email" className="form-control"
                                        {...register("workEmail", {
                                            required: {
                                                value: true,
                                                message: emailWarningMessage,
                                            },
                                            pattern: {
                                                // eslint-disable-next-line
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            },
                                        })}
                                        name="workEmail"
                                        id="workmemail"
                                        placeholder="@"
                                        required
                                    />

                                    {errors.workEmail ? (
                                        <p className="error-label">{emailMissingMessage}</p>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message" className="text-uppercase" >{messageLabel}</label>
                                    <textarea
                                        {...register("message",{
                                            required: {
                                                value: true,
                                                message: messageMissingMessage,
                                            },
                                        })}
                                        name="message"
                                        id="message"
                                        type="text"
                                        placeholder={messageLabel}
                                        required
                                        className="form-control"
                                    />
                                    {errors.message && errors.message.type === "required" && (
                                        <p className="error-label">{errors.message.message}</p>
                                    )}
                                </div>
                                <button type="submit" className="btn btn-outline-primary float-right py-3 px-4">{submitButtonLabel}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
