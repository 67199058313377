import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Scroll from 'react-scroll';

export default function ScrollToTop(){
  const location = useLocation();


  useEffect(() => {
    Scroll.animateScroll.scrollToTop({ delay: 0, duration: 400, offset: 0, smooth: true })
  }, [location])

  return null;
}
