import React, { useState, useEffect } from 'react';
import NavigationItem from './NavigationItem';
import { useLocation, Link } from "react-router-dom";
import phone from '../../Assets/Images/navigation-phone-icon.svg'
import locationIcon from '../../Assets/Images/navigation-location-icon.svg'
import ScrollArea from 'react-scrollbar'





export default function Navigation({ showNavigation, setShowNavigation, pages }) {
    const wrapperClasses = ["arslanbas-navigation-wrapper"];
    const [closeText, setCloseText] = useState("Kapat");

    const onClickNavigationItem = () => {
        setShowNavigation(false)
    }

    showNavigation && wrapperClasses.push("arslanbas-navigation-wrapper--opened");
    return (
        <div className={wrapperClasses.join(' ')}>
            <div className="arslanbas-navigation">
                <div className="row justify-content-center align-items-center h-100" >
                    <div className="col-10">
                        <div className="row">
                            <div className="col-12">
                                <p onClick={() => setShowNavigation(false)} className="arslanbas-navigation--close mb-5">{closeText}</p>
                            </div>
                            <div className="col-12 mb-5">
                                <ul className="arslanbas-navigation--group">
                                    {
                                        pages?.map(page => <NavigationItem withChildren={Array.isArray(page.children) && page.children.length} onClickNavigationItem={onClickNavigationItem} page={page} pages={pages} />)
                                    }
                                </ul>
                            </div>
                            <div className="col-12 arslanbas-navigation--contact">
                                <div className="row align-items-center">
                                    <div className="col-2 col-md-1">
                                        <img src={locationIcon}></img>
                                    </div>
                                    <div className="col-10 col-md-11">
                                        <p style={{ margin: 0 }}>Cevizli Mh. Bulut Plaza Tansel Cd. No:18/80 / İstanbul</p>
                                    </div>
                                    <div className="col-2 col-md-1">
                                        <img src={phone}></img>
                                    </div>
                                    <div className="col-10 col-md-11">
                                        <p style={{ margin: 0 }}>+90 216 399 71 04</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
