import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

export default function SubNavigationMenuItem({ item, onClick }) {
  return (
    <li key={item.id}>
      {" "}
      <Link to={item.slug} onClick={() => onClick()}>
        {item.title.length > 35 ? item.title.substr(0, 33) + "..." : item.title}
      </Link>
    </li>
  );
}
